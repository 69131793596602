import styles from '../ProjectsPage.module.css';

export interface ProjectButton {
	title: string;
	onClick: () => void;
}

export interface ProjectProps {
	title?: string;
	hide?: boolean;
	image?: string;
	texts?: Array<string>;
	buttons?: Array<ProjectButton>;
}

export const Project = (props: ProjectProps) => {
	const texts = props.texts ? props.texts.map((text, i) => <h3 key={i}>{text}</h3>) : <></>;

	const buttons = props.buttons ? (
		props.buttons.map((button, i) => {
			return (
				<button key={i} className={styles.Button} onClick={button.onClick}>
					{button.title}
				</button>
			);
		})
	) : (
		<></>
	);

	if (!!props.hide) {
		return <></>;
	} else {
		return (
			<div className={styles.Project}>
				{props.image ? <img className={styles.ProjectImage} src={props.image}></img> : <></>}
				{props.title ? <h2>{props.title}</h2> : <></>}
				{texts}
				{buttons}
			</div>
		);
	}
};
