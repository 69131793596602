import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AboutPage } from './pages/aboutPage/AboutPage';
import { HomePage } from './pages/homePage/HomePage';
import { ContactPage } from './pages/contactPage/ContactPage';
import { ProjectsPage } from './pages/projectsPage/ProjectsPage';
import { YugoDevPage } from './pages/yugoPage/YugoDevPage';

function App() {
	console.clear();

	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<HomePage />} />
				<Route path='/contact' element={<ContactPage />} />
				<Route path='/about' element={<AboutPage />} />
				<Route path='/projects' element={<ProjectsPage />} />
				<Route path='/yugo' element={<YugoDevPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
