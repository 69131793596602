import styles from '../YugoDevPage.module.css';

export interface YugoGameProps {
	url: string;
	image: string;
}

export const YugoGame = (props: YugoGameProps) => {
	return (
		<div
			onClick={() => {
				window.open(props.url, '_blank');
			}}
			className={styles.Game}
		>
			<img className={styles.GameImage} src={props.image}></img>
		</div>
	);
};
