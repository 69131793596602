import styles from './ProjectsPage.module.css';
import { TopBar } from '../../components/topBar/TopBar';
import { Project } from './components/Project';
import blade_battle_arena from './images/blade_battle_arena.png';
import tic_tac_gems from './images/tic_tac_gems.png';
import high_shots_low_cups from './images/high_shots_low_cups.png';
import { useNavigate } from 'react-router';

export const ProjectsPage = () => {
	const navigate = useNavigate();

	return (
		<>
			<div className={styles.Main}>
				<TopBar useBackButton={true} />

				<div className={styles.Line}></div>
				<div className={styles.Line}></div>

				<h1>Projects</h1>

				<Project
					image={blade_battle_arena}
					texts={[
						`Blade Battle Arena is the most solid game that laid the foundations of the frontend typescript engine, 
						subsequently leading to the massive relief of the following html canvas projects.`,
						`In addition to those mentioned, in 2019 a 2D physics engine is created from scratch in order to 
						fulfill the game requirements. Later on was published as npm package.`,
						`Below you can access a prototype of the game with 2 players.\nUse w a s d and arrows to move.`,
					]}
					buttons={[
						{
							title: 'Play',
							onClick: () => {
								window.open('https://padureanu-it.com/pfiles/blade_battle_arena', '_blank');
							},
						},
					]}
				/>
				<Project
					image={tic_tac_gems}
					texts={[
						`Tic Tac Gems was created to complement the MVP for a larger project.`,
						`The project of which this game was a part is called Orangle. A mobile application 
						that turns your phone into a controller so you can play web games with it.`,
					]}
					buttons={[
						{
							title: 'Play',
							onClick: () => {
								window.open('https://padureanu-it.com/pfiles/tic_tac_gems', '_blank');
							},
						},
					]}
				/>
				<Project
					image={high_shots_low_cups}
					texts={[`This was the second game created for Orangle mobile Application`]}
					buttons={[
						{
							title: 'Play',
							onClick: () => {
								window.open('https://padureanu-it.com/pfiles/high_shots_low_cups', '_blank');
							},
						},
					]}
				/>
				<Project
					title='Pixi.JS Spine Testing Tool'
					texts={[]}
					buttons={[
						{
							title: 'Use',
							onClick: () => {
								window.open('https://padureanu-it.com/ptools/pixi_spine_testing/', '_blank');
							},
						},
					]}
				/>

				<Project
					title='Yugo'
					hide={true}
					texts={[]}
					buttons={[
						{
							title: 'Dev Page',
							onClick: () => {
								navigate('/yugo');
							},
						},
					]}
				/>
			</div>
		</>
	);
};
