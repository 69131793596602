import styles from './ContactPage.module.css';
import profile from './profile.jpg';
import { TopBar } from '../../components/topBar/TopBar';

export const ContactPage = () => {
	return (
		<div className={styles.Main}>
			<TopBar useBackButton={true} />

			<div className={styles.Container}>
				<h1>Contact</h1>
				<h2>This is how you can find us</h2>
			</div>

			<div className={styles.Line}>
				<svg
					className={styles.Animation}
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 1200 560'
				>
					<path
						className={styles.line1}
						fill='none'
						stroke='#f4d160'
						strokeWidth='11'
						strokeMiterlimit='10'
						d='M0,205.122c0,0,1.873-5.227,34.181,11.771
					c34.482,18.143,59.697,52.119,93.893,71.865c58.23,33.623,127.729,35.703,192.193,20.418
					c84.208-19.967,120.448-106.473,201.401-131.238c33.513-10.252,72.097-7.987,100.849,12.053
					c13.948,9.722,25.016,23.027,38.729,33.078c41.312,30.281,101.44,28.432,150.36,28.432c58.764,0,1187.401,0,1187.401,0'
					/>
					<path
						className={styles.line2}
						fill='none'
						stroke='#fbeeac'
						strokeWidth='7'
						strokeMiterlimit='10'
						d='M7.622,68.997
					c63.526,16.725,114.958,46.718,161.474,93.375c58.574,58.752,124.397,129.296,215.396,122.491
					c36.201-2.707,69.877-18.493,104.376-29.793c92.193-30.198,191.378-28.556,288.366-26.362
					c222.659,5.037,443.983,4.791,666.654,4.791c85.665,0,555.119,0,555.119,0'
					/>
					<path
						className={styles.line3}
						fill='none'
						stroke='#f4d160'
						strokeWidth='11'
						strokeMiterlimit='10'
						d='M3.753,344.931
					c0,0,260.692-109.142,307.727-118.406c47.035-9.264,132.368-18.393,195.794,3.404s243.095,67.78,340.015,64.93
					c96.92-2.851,204.513-72.49,236.582-89.594c32.069-17.104,65.547-14.766,143.225-14.766c77.679,0,771.911,0,771.911,0'
					/>
					<path
						className={styles.line4}
						fill='none'
						stroke='#fbeeac'
						strokeWidth='7'
						strokeMiterlimit='10'
						d='M10.628,398.243
						c98.501,20.268,202.926,9.114,295.368-30.252c110.86-47.21,211.1-122.805,335.982-128.2c91.338-3.945,184.697,28.631,270.85,55.526
						c52.736,15.855,159.623,42.183,257.969,42.183s828.21,0,828.21,0'
					/>
				</svg>
			</div>

			<div className={styles.Profile}>
				<div>
					<div className={styles.InfoDiv}>
						<p className={styles.InfoText}>Email</p>
						<h3 className={styles.InfoText}>padureanu.itservices@gmail.com</h3>
					</div>
					<div className={styles.InfoDiv}>
						<p className={styles.InfoText}>Phone</p>
						<h3 className={styles.InfoText}>+40 730 738 528</h3>
					</div>
				</div>
			</div>

			<span className={styles.materialSymbolsOutlined}>location_on</span>

			<h3 className={styles.InfoText}>Bucharest</h3>
			<p className={styles.InfoText}>Romania</p>
		</div>
	);
};
