import styles from './YugoDevPage.module.css';
import { TopBar } from '../../components/topBar/TopBar';
import yugo_logo from './yugo_logo.png';
import { YugoGame } from './components/YugoGame';

import thorfinns_journey from './images/thorfinns_journey.png';
import wild_island from './images/wild_island.png';

export const YugoDevPage = () => {
	return (
		<>
			<TopBar useBackButton={true} />

			<div className={styles.Bg} />

			<div className={styles.Main}>
				<div className={styles.Line}></div>

				<img className={styles.Logo} src={yugo_logo}></img>

				<div className={styles.Line}></div>

				<div className={styles.GamesDiv}>
					<YugoGame url={'https://padureanu-it.com/projects/thorfinns_journey/'} image={thorfinns_journey} />
					<YugoGame url={'https://padureanu-it.com/projects/wild_island/'} image={wild_island} />
				</div>

				<div className={styles.Line}></div>
			</div>
		</>
	);
};
