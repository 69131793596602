import { useNavigate } from 'react-router';
import styles from './HomePage.module.css';
import { TopBar } from '../../components/topBar/TopBar';
import { HomePageAnimation } from './HomePage.Animation';

export const HomePage = () => {
	const navigate = useNavigate();

	return (
		<>
			<HomePageAnimation />
			<div className={styles.Main}>
				<TopBar />

				<h1>Software made simple</h1>
				<h2>All you need in one place</h2>

				<div className={styles.Line}></div>

				<div className={styles.ButtonsContainer}>
					<button className={styles.Button} onClick={() => navigate('/projects')}>
						Projects
					</button>
				</div>

				<div className={styles.Bottom}>
					<button className={styles.BottomButton} onClick={() => navigate('/about')}>
						About
					</button>
					<button className={styles.BottomButton} onClick={() => navigate('/contact')}>
						Contact
					</button>
				</div>
			</div>
		</>
	);
};
