import styles from './HomePage.module.css';

export const HomePageAnimation = () => {
	return (
		<>
			<div className={styles.Circles}>
				<div className={styles.Circle1}>
					<div className={styles.Circle1Moon1}></div>
				</div>
				<div className={styles.Circle2}>
					<div className={styles.Circle2Moon1}></div>
					<div className={styles.Circle2Moon2}></div>
				</div>
				<div className={styles.Circle3}>
					<div className={styles.Circle3Moon1}></div>
				</div>
			</div>
		</>
	);
};
