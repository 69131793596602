import styles from './AboutPage.module.css';
import { TopBar } from '../../components/topBar/TopBar';
import { AboutPageAnimations } from './AboutPage.Animation';

export const AboutPage = () => {
	return (
		<>
			<div className={styles.Main}>
				<TopBar useBackButton={true} />

				<div className={styles.Container}>
					<h1>About</h1>
					<h2>We are a frontend multi technologies creative studio</h2>
				</div>

				<div className={styles.InfoContainer}>
					<h3 className={styles.InfoText}>
						Starting in 2018, we have contributed to over 100 projects in the field of mobile applications,
						websites and gaming. The main keywords in our software developement is "solid", "clean" and
						"fast".
					</h3>
					<h2>Some technologies we use</h2>
					<h3>Pixi.js Three.js, React, React Native, Electron.js, Expo.js</h3>
				</div>
			</div>

			<AboutPageAnimations />
		</>
	);
};
